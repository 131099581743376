.home {
    overflow: hidden;
    position: relative;
    background: #eeeeee;
    position: relative; 
    margin-top: 65px;
    color: #fff;
    padding-bottom: 20px;
    margin-bottom: 14px;
    z-index: 1;
    @media only screen and (max-width: 991px){
        margin-top: 60px;
    }
    @media only screen and (max-width: 1919px) {

    }
    @media only screen and (max-width: 1350px) and (orientation: portrait),
    screen and (max-width: 1080px) {

    }
    @media only screen and (max-width: 991px) {

    }
    @media only screen and (max-width: 767px){
        margin-bottom: 0;
        padding-bottom: 32px;
    }
    &:before {
        content: "";
        position: absolute;
        top: -2px;
        left: 0;
        width: 58%;
        height: 970px;
        background: url('../img/home-bg.png') no-repeat;
        background-size: contain;
        background-position: top -44px right;
        @media only screen and (max-width: 1480px){
            background-image: url('../img/home-bg-pl.png');
            width: 100%;
            background-size: 100%;
        }
        @media only screen and (max-width: 1279px) {
            width: 126%;
        }
        @media only screen and (max-width: 767px){
            background-image: url('../img/home-bg-mob.png');
            width: 100%;
            height: 850px;
        }
        @media only screen and (max-width: 540px){
            height: 719px;
            top: -15px;
        }
    }
    &:after {
        content: "";
        position: absolute;
        top: -2px;
        left: 0;
        width: 34%;
        height: 922px;
        background: #262627;
        z-index: -1;

        @media only screen and (max-width: 1480px){
            content: none;
        }
        @media only screen and (max-width: 991px){

        }
        @media only screen and (max-width: 767px){
            content: "";
            width: 100%;
            top: 0;
            background: url('../img/home-bg-black-mob.png') no-repeat;
            background-position: top 819px center;
            background-size: contain;
            height: 1405px;
        }
        @media only screen and (max-width: 540px){
            background-size: 100%;
            background-position: top 650px center;
        }
    }
    &__body {
        position: relative;
        padding-top: 17px;
        margin-bottom: 128px;
        @media only screen and (max-width: 991px){
            margin-bottom: 50px;
        }
        &:after {
            content: "";
            position: absolute;
            top: 9%;
            left: 88%;
            width: 1038px;
            // max-width: 918px;
            height: 686px;
            background: url('../img/home-bike.png') no-repeat;
            background-size: contain;
            background-position: top left;
            pointer-events: none;
            @media only screen and (max-width: 1480px){
                content: none;
            }
            @media only screen and (max-width: 1279px){
                top: 15%;
                left: 100%;
                width: 920px;
                height: 633px;
            }
            @media only screen and (max-width: 991px){
                background-size: contain;
                width: 650px;
                height: 390px;
                left: 112%;
            }
            @media only screen and (max-width: 767px){
                content: none;
            }
        }
    }
    &__logo {
        opacity: 1;
        transform: translateY(0) translateX(0) scale(1);
        transition: 0.3s;
        @media only screen and (max-width: 767px){
            display: none;
        }
        &.active {
            opacity: 0;
            transform: translateY(-100px) translateX(-100px) scale(0);
        }
        img {
            position: relative;
            margin: -83px 0 -34px;
            left: -46px;
            max-width: 434px;
            @media only screen and (max-width: 991px){
                margin-top: -64px;
            }
        }
    }
    &__title {
        font-weight: normal;
        font-size: 60px;
        color: #fff;
        margin-top: 0;
        margin-bottom: 44px;
        @media only screen and (max-width: 991px){
            font-size: 40px;
        }
        @media only screen and (max-width: 767px){
            padding-top: 47%;
            font-size: 60px;
            text-align: center;
            font-weight: 700;
        }
        @media only screen and (max-width: 500px){
            padding-top: 35%;
        }
        .text-accent{
            @media only screen and (max-width: 767px){
                display: block;
                font-weight: 400;
                font-size: 48px;
            }
        }
    }
    &__subtitle {
        font-size: 18px;
        font-weight: 700;
        color: #eeeeee;
        padding-left: 19px;
        margin-bottom: 17px;
        @media only screen and (max-width: 767px){
            max-width: 315px;
            margin-left: auto;
            margin-right: auto;
            padding-left: 0;
        }
    }
    &__text {
        font-size: 18px;
        line-height: 1.5;
        color: #eeeeee;
        max-width: 389px;
        padding-left: 19px;
        margin-bottom: 51px;
        @media only screen and (max-width: 991px){
            font-size: 14px;
            margin-bottom: 35px;
        }
        @media only screen and (max-width: 767px){
            margin-left: auto;
            margin-right: auto;
            max-width: 315px;
            padding-left: 0;
            margin-bottom: 66px;
        }
    }
    &__btn {
        max-width: 315px;
        @media only screen and (max-width: 767px){
            margin-left: auto;
            margin-right: auto;
            max-width: 380px;
        }
        @media only screen and (max-width: 419px){
            max-width: 100%;
        }
        .btn {
            @media only screen and (max-width: 419px) {
                max-width: 100%;
            }
        }
    }
    &__item {
        display: block;
        height: 305px;
        padding: 26px;
        background: #fff;
        background-repeat: no-repeat;
        background-position: bottom center;
        background-size: contain;
        @media only screen and (max-width: 1279px){
            height: 255px;
        }
        @media only screen and (max-width: 991px){
            height: 198px;
            padding: 26px 12px;
        }
        @media only screen and (max-width: 767px){
            height: 293px;
            margin: 0 auto 18px;
            max-width: 380px;
        }
        @media only screen and (max-width: 419px){
            // max-width: 315px;
            height: 222px;
        }
        &--teh {
            background-image: url('../img/service1.jpg');
            @media only screen and (max-width: 767px){
                background-size: 100%;
            }
        }
        &--repair {
            background-image: url('../img/service2.jpg');
        }
        &--upgrade {
            background-image: url('../img/service3.jpg');
        }
        &:hover {
            box-shadow: 0.105px 2.998px 7.5px rgba(0,0,0,0.51);
            .home__name {
                color: $accent;
            }
        }
    }
    &__name {
        font-size: 35px;
        text-align: center;
        @media only screen and (max-width: 1279px){
            font-size: 29px;
        }
        @media only screen and (max-width: 991px){
            font-size: 22px;
        }
    }
    &__shop {
        display: block;
        margin-top: 24px;
        padding: 12px 12px 22px;
        background: #000 url('../img/shop-bg.png') no-repeat;
        background-size: 100% 100%;
        @media only screen and (max-width: 1279px){
            background-size: contain;
            background-position: center;
        }

        @media only screen and (max-width: 767px){
            margin-top: 8px;
            padding: 20px 22px 22px;
            background-image: url('../img/shop-bg-mob.png');
            background-position: center bottom -45px;
            background-size: 261px;
            padding-bottom: 105px;
            max-width: 380px;
            margin-left: auto;
            margin-right: auto;
        }
        .home__name {
            color: #fff;
            font-size: 36px;
            line-height: 1.3;
            @media only screen and (max-width: 767px){
                font-size: 24px;
                line-height: 1;
                margin-bottom: 13px;
            }
        }
        &-text {
            color: #fff;
            font-size: 16px;
            text-align: center;
        }
        &:hover {
            box-shadow: 0.105px 2.998px 7.5px rgba(0,0,0,0.51);
            .home__name {
                color: $accent;
            }
        }
    }
}


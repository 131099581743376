.upgrade {
    position: relative;
    padding: 91px 0 40px;
    background: url('../img/det-bg.png') no-repeat;
    background-size: cover;
    background-position: 0 0;
    transition: background-position .5s ease-out;
    z-index: 1;
    overflow: hidden;

    @media only screen and (min-width: 1481px) {
        background-size: 100% 100%;
        // padding-bottom: 129px;
    }
    @media only screen and (max-width: 767px){
        overflow: hidden;
        // background-image: url('../img/det-bg-mob.jpg');
        padding-top: 49px;
        padding-bottom: 79px;
        margin-bottom: 32px;
    }
    &__details {
        position: absolute;
        img {
            max-width: 100%;
        }
    }
    .upgrade1 {
        top: 50px;
        left: 0;
        @media only screen and (max-width: 767px){
            display: none;
        }
    }
    .upgrade2 {
        bottom: 13%;
        left: 9%;
        @media only screen and (max-width: 767px){
            display: none;
        }
    }
    .upgrade3 {
        bottom: 15%;
        left: 39%;
        @media only screen and (max-width: 767px){
            display: none;
        }
    }
    .upgrade4 {
        top: 75px;
        right: 0;
        @media only screen and (max-width: 767px){
            width: 100px;
        }
    }
    .upgrade5 {
        top: 17%;
        right: 25%;
        @media only screen and (max-width: 767px){
            display: none;
        }
    }
    .upgrade6 {
        bottom: 210px;
        right: 18%;
        @media only screen and (max-width: 767px){
            right: auto;
            left: 0;
            img {
                opacity: 0;
            }
            background: url('../img/det6.png') no-repeat;
        }
    }
    .title {
        color: #fff;
    }
    &__line {
        padding: 18px 0;
        font-weight: 700;
        font-size: 18px;
        color: #fff;
        transition: transform 1.3s;
        &--yellow {
            color: #ffcc33;
        }
        &:nth-child(1){
            transform: translateX(23%) translateY(29%);
            transform: matrix(1, 0, 0, 1, 187.91, 16.53);
            @media only screen and (max-width: 767px){
                transform: translateX(7%) translateY(60%);
            }
        }
        &:nth-child(2){
            text-align: center;
            transform: translateX(5%) translateY(78%);
            @media only screen and (max-width: 767px){
                transform: translateX(13%) translateY(47%);
            }
            @media only screen and (max-width: 419px){
                max-width: 256px;
            }
        }
        &:nth-child(3){
            text-align: right;
            transform: translateX(-2%) translateY(87%);
            @media only screen and (max-width: 767px){
                transform: translateX(-8%) translateY(101%);
            }
        }
        &:nth-child(4){
            transform: translateX(5%) translateY(1%);
            @media only screen and (max-width: 767px){
                transform: translateX(0%) translateY(-56%);
            }
        }
        &:nth-child(5){
            text-align: center;
            transform: translateX(16%) translateY(-18%);
            @media only screen and (max-width: 767px){
                transform: translateX(0) translateY(18%);
            }
        }
        &:nth-child(6){
            transform: translateX(3%) translateY(1%);
            @media only screen and (max-width: 767px){
                transform: translateX(23%) translateY(31%);
                text-align-last: center;
                max-width: 230px;
            }
        }
    }
    &__btn {
        display: flex;
        justify-content: flex-end;
        margin: 24px 0 30px;
        @media only screen and (max-width: 767px){
            justify-content: center;
            margin-top: -35px;
            margin-left: -30px;
            width: 380px;
        }
        @media only screen and (max-width: 575px){
            margin-left: auto;
            margin-right: auto;
        }
        @media only screen and (max-width: 419px){
            width: auto;
        }
        .btn {
            @media only screen and (max-width: 767px){
                top: 107px;
            }
        }
    }
    .tags {
        line-height: 1.5;
        letter-spacing: 1px;
    }
}
.leaflet-bottom.leaflet-right {
    z-index: 1;
}
.contact {
    position: relative;
    background: #eeeeee;
    padding-top: 59px;
    margin-top: -50px;
    @media only screen and (max-width: 767px){
        margin-top: -100px;
        padding-top: 80px;
    }
    .title {
        margin-bottom: 37px;
        @media only screen and (max-width: 767px){
            margin-bottom: 22px;
        }
    }
    &__container {
        @media only screen and (max-width: 767px){
            padding: 0;
            .row {
                margin: 0;
            }
        }
    }
    &__body {
        opacity: 0;
        position: relative;
        color: #fff;
        z-index: 1;
        width: 100%;
        max-width: 33%;
        @media only screen and (max-width: 1279px){
            max-width: 44%;
        }
        @media only screen and (max-width: 991px){
            max-width: 53%;
        }
        @media only screen and (max-width: 767px){
            max-width: 100%;
        }
        &:before {
            content: "";
            position: absolute;
            width: 78%;
            height: 90%;
            top: -5px;
            left: -5px;
            background: $accent;
            z-index: -1;
            @media only screen and (max-width: 749px) {
                left: 0;
            }
        }
    }
    &__info {
        margin-top: 5px;
        padding: 44px 44px 20px;
        background: #262627;
        @media only screen and (max-width: 767px){
            padding: 50px 14px 20px;
        }
    }
    &__text {
        display: block;
        font-size: 18px;
        text-align: center;
        margin-bottom: 19px;
        color: #fff;
    }
    &__adress {
        line-height: 1.5;
        margin-bottom: 20px;
    }
    &__items {
        font-size: 18px;
        margin: 42px 0 40px;
        text-align: center;

        @media only screen and (max-width: 767px){
            margin-bottom: 34px;
        }
        a {
            display: block;
            color: #fff;
            & + a {
                margin-top: 13px;
            }
            &:hover {
                color: $accent;
            }
        }
    }
    &__link {
        font-size: 16px;
        text-decoration: underline;
        color: #fff;
        @media only screen and (max-width: 767px){
            margin-top: 10px;
            margin-bottom: 10px;
        }
        &:hover {
            color: $accent;
        }
    }
    &__mail {
        &:hover {
            color: $accent;
        }
    }
    &__btn {
        margin-top: 30px;
        letter-spacing: -0.5px;
        @media only screen and (max-width: 767px){
            margin-top: 35px;
        }
        .btn {
            @media only screen and (max-width: 767px){
                &.btn--white {
                    padding: 14px 41px;
                }
            }
        }
    }
    &__social {
        margin-top: 30px;
        font-size: 14px;
        text-align: center;
    }
    .icon {
        width: 30px;
        height: 30px;
        fill: #fff;
        transition: 0.3s;
        &:hover {
            fill: $accent;
        }
    }
}
#map {
    position: absolute;
    width: 100%;
    height: 100%;
    height: calc(100% - 78px);
    z-index: 1;

    @media only screen and (max-width: 767px){
        position: relative;
        height: 501px;
    }
}
.social {
    &__items {
        display: flex;
        justify-content: center;
        margin-top: 15px;
        margin-bottom: 18px;
        @media only screen and (max-width: 767px){
            margin-top: 14px;
            margin-bottom: 28px;
        }
    }
    &__item + .social__item {
        margin-left: 28px;
    }
}
.leaflet-marker-pane :hover {
    img {
        opacity: 0.8;
    }
    .my-div-icon {
        &:after {
            opacity: 0.8;
        }
    }
}
.my-div-icon {
    position: relative;
    &:after {
        content: "Велохата";
        position: absolute;
        top: 7px;
        left: 30px;
        font-size: 20px;
        text-transform: uppercase;
        font-weight: 700;
        color: #262627;
    }
    // width: 100px;
    // height: 100px;
}
// .map-scroll:before {
//     pointer-events: none;
//     content: 'Use ctrl + scroll to zoom the map';
//     position: absolute;
//     top: 60%;
//     left: 50%;
//     z-index: 999;
//     font-size: 30px;
// }
// .map-scroll:after {
//     pointer-events: none;
//     position: absolute;
//     left: 0;
//     right: 0;
//     bottom: 0;
//     top: 0;
//     content: '';
//     // background: rgba(0, 0, 0, 0.18);
//     z-index: 999;
// }

@keyframes fadeInUp {
    from {
      opacity: 0;
      transform: translate3d(0, 100%, 0);
    }
  
    to {
      opacity: 1;
      transform: translate3d(0, 0, 0);
    }
  }
  
  .fadeInUp {
    animation-name: fadeInUp;
    animation-fill-mode: both;
  }
  .leaflet-top.leaflet-left {
      z-index: 1;
  }
.footer {
    .container {
        position: relative;
    }
}
.sticky {
    padding: 5px 0;
    opacity: 0;
    color: #000;
    background: #ffcc33;
    transition: opacity transform 0.3s;
    z-index: 2;
    transform: translateY(100px);
    -webkit-transform: translateY(100px);
    @media only screen and (max-width: 767px){
        display: none;
    }
    &.active {
        opacity: 1;
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100%;
        transform: translateY(0);
        -webkit-transform: translateY(0);
    }
    .wrap {
        display: flex;
        align-items: center;
        justify-content: space-around;
    }
    &__text {
        font-size: 24px;
        padding-left: 27px;
        @media only screen and (max-width: 991px){
            font-size: 20px;
        }
    }
    &__btn {
        .btn {
            font-size: 18px;
            min-width: 259px;
            padding: 16px 12px;
        }
    }
    .close {
        width: 30px;
        top: 0;
        right: 15px;
        background: transparent;
        font-size: 18px;
        color: #262627;
        &:hover {
            opacity: 0.8;
            color: #262627;
        }
    }

}
.callback {
    cursor: pointer;
    display: flex;
    align-items: center;
    position: fixed;
    bottom: 108px;
    right: 40px;
    transition: 0.3s;
    transform: scale(0) translate(185px, 100px);
    -webkit-transform: scale(0) translate(185px, 100px);
    z-index: 1;
    @media only screen and (max-width: 767px){
        bottom: 20px;
        right: 20px;
    }
    &.active {
        display: flex;
        transform: scale(1) translate(0, 0);
        -webkit-transform: scale(1) translate(0, 0);
    }
    &__body {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 72px;
        height: 72px;
        border-radius: 50%;
        background: #262627;
        margin-right: 4px;
        border: 7px solid #fff;
        box-shadow: 0.105px 2.998px 7.5px rgba(0,0,0,0.38);
        animation: circle3 3s linear infinite;
        transition: 0.3s;
        &:before, &:after {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            border: 1px solid $accent;
            border-radius: 50%;
            transform: scale(0.8);
        }
        &:before {
            animation: circle1 2s cubic-bezier(.9,.24,.62,.79) infinite;
        }
        &:after {
            border-width: 2px;
            transform: scale(0.6);
            animation: circle2 2s cubic-bezier(.9,.24,.62,.79) infinite;
        }
    }
    .icon {
        width: 22px;
        height: 22px;
        fill: $accent;
    }
    &:hover {
        svg {
            fill: #fff;
        }
    }
}
@keyframes circle1 {
    from {
        transform: scale(0.8);
        -webkit-transform: scale(0.8);
        opacity: 1;
    }
    to {
        transform: scale(2);
        -webkit-transform: scale(2);
        opacity: 0;
    }
}
@keyframes circle2 {
    from {
        transform: scale(0.6);
        -webkit-transform: scale(0.6);
        opacity: 1;
    }
    to {
        transform: scale(2);
        -webkit-transform: scale(2);
        opacity: 0;
    }
}
@keyframes circle3 {
    0% {
        transform: scale(1);
        -webkit-transform: scale(1);
    }
    50% {
        transform: scale(1.1);
        -webkit-transform: scale(1.1);
    }
    100% {
        transform: scale(1);
        -webkit-transform: scale(1);
    }
}

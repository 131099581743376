@import "vars";

.menu-toggle {
    display: none;
}
.menu__text--mob {
    display: none;
    position: relative;
    @media only screen and (max-width: 767px) {
        display: block;
        font-size: 58px;
        line-height: 1;
        color: #fff;
        text-align: center;
        padding-bottom: 10px;
        &:after {
            content: "";
            position: absolute;
            bottom: 0;
            left: 50%;
            margin-right: -50%;
            transform: translate(-50%, 0);
            width: 66px;
            height: 1px;
            background: $accent;
        }
    }
}
.cross {
    display: none;
}
.menu-wrap {
    margin-left: auto;
    width: 65.5%;
    height: 100%;
    padding-left: 25px;
    padding-right: 25px;
    @media only screen and (max-width: 1279px) {
        width: auto;
    padding-left: 20px;
    padding-right: 20px;
    }
    @media only screen and (max-width: 991px){
        padding: 0;
    }
}
.main-menu {
    font-size: 18px;
    height: 100%;
    @media only screen and (max-width: 1279px) {
        font-size: 15px;
    }
    @media only screen and (max-width: 991px){
        font-size: 13px;
    }
    @media only screen and (max-width: 767px) {
        font-size: 30px;
        line-height: 1;
    }
    ul {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 100%;
    }
    a  {
        display: flex;
        padding: 7px;
        position: relative;
        @media only screen and (max-width: 1279px){
            padding: 6px;
        }
        &:after {
            content: "";
            position: absolute;
            left: 7px;
            bottom: 0;
            width: 0;
            height: 1px;
            background: #fff;
            transition: 0.3s;
        }
        &.active,
        &:hover {
            color: $accent;
            text-shadow: 0 0 .9px $accent, 0 0 .9px $accent, 0 0 .9px $accent; 
            &:after {
                width: 32px;
                @media only screen and (max-width: 767px){
                    content: none;
                }
            }
        }
    }

}
@media only screen and (max-width: 767px) {
    .menu-toggle {
        display: block;
        width: 29px;
        height: 28px;
        position: absolute;
        top: 50%;
        transform: translate(0, -50%);
        left: 20px;
        cursor: pointer;
        z-index: 4;
        .one, .two, .three {
            width: 100%;
            height: 6px;
            background: #fff;
            margin: 6px auto;
            backface-visibility: hidden;
            transition-duration: .3s;
        }
        .one {
            margin-top: 0;
        }
        .two {
            width: 80%;
            background: $accent;
        }
        .three {
            margin-bottom: 0;
        }
        &.active {
            overflow: hidden;
            .one,
            .three {
                margin: 8px auto;
            }
            .one {
                transform: rotate(57deg) translate(0, 9px);
                width: 150%;
            }
            .two {
                opacity: 0;
            }
            .three {
                transform: rotate(-57deg) translate(19px, -21px);
                width: 150%;
            }
        }
    }
    .main-menu {
        display: none;
        &.active {
            display: block;
            position: fixed;
            overflow: auto;
            width: 87%;
            height: calc(100% - 75px);
            background-color: rgba(0, 0, 0, 0.85);
            z-index: 3;
            left: 0;
            top: 74px;
            ul {
                display: block;
                padding: 20px;
                color: #090f21;
            } 
        }
        a {
            padding: 24px 0;
        }
        li + li {
             border-top: 1px solid $accent;
        }
    }
    .menu-wrap {
        position: absolute;
        left: 0;
    }

}

.teh {
    padding: 56px 0 65px;
    color: #fff;
    background: #eeeeee;
    background: url('../img/teh-bg.png') no-repeat;
    background-size: cover;
    position: relative;
    z-index: 1;
    @media only screen and (min-width: 1481px) {
        background-size: 100% 100%;
    }
    @media only screen and (max-width: 991px){
        padding-top: 83px;
        padding-bottom: 133px;;
    }
    @media only screen and (max-width: 767px){
        background-image: url('../img/teh-bg-mob.png');
        background-size: 100% 100%;
        padding-top: 51px;
        padding-bottom: 75px;
        margin-top: -47px;
    }
    .title {
        margin-bottom: 10px;
        @media only screen and (max-width: 767px){
            margin-bottom: 26px;
        }
    }
    .subtitle {
        margin-bottom: 18px;
        @media only screen and (max-width: 767px){
            font-size: 16px;
            margin-bottom: 27px;
        }
        &.text--bold {
            @media only screen and (max-width: 767px){
                letter-spacing: 1px;
            }

        }
    }
    &__item {
        color: #262626;
        font-size: 16px;
        z-index: 1;
        @media only screen and (max-width: 991px){
            margin-bottom: 21px;
        }
        @media only screen and (max-width: 767px){
            margin-bottom: 20px;
        }
        &:hover {
            .teh__item-wrap {
                background: #ededec;
                &:before {
                    background: #d2a82a;
                    bottom: -10px;
                    right: -10px;
                    transition: 0.5s;
                }
            }
        }
    }
    &__item-wrap {
        display: flex;
        padding: 7% 10.5%;
        position: relative;
        height: 100%;
        background: rgba(255, 255, 255, 0.6);
        transition: 0.3s;
        @media only screen and (max-width: 991px){
            background: #eeeeee;
            padding: 3% 8%;
        }
        @media only screen and (max-width: 767px){
            padding: 10% 6%;
        }
        &:before {
            content: "";
            position: absolute;
            width: 90%;
            height: 90%;
            bottom: 0;
            right: 0;
            background: transparent;
            z-index: -1;
        }
    }
    &__item--center {
        margin: auto 0;
    }
    &__name {
        font-weight: 700;
        padding-left: 30px;
    }
    &__btn {
        width: 38%;
        margin-left: 11px;
        letter-spacing: -1px;
        @media only screen and (max-width: 767px){
            width: 100%;
            margin-left: 0;
        }
        .btn {
            @media only screen and (max-width: 767px){
                padding: 18px 20px;
                max-width: 100%;
            }
        }
    }
    .tags {
        width: 62%;
    }
}
.container__teh {
    max-width: 1405px;
    padding: 0 20px;
    margin: 25px auto 44px;
    @media only screen and (max-width: 767px){
        margin-bottom: 14px;
        max-width: 540px;
    }
}
.tags-wrap {
    display: flex;
    .tags {
        margin: auto 0;
    }
}
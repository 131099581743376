.modal {
  max-height: 100vh;
  overflow: auto;
  display: flex;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: fixed;
  z-index: 100;
  background-color: rgba(0, 0, 0, 0.8);
  justify-content: center;
  // align-items: center;
  @media only screen and (max-width: 749px) {
    background: transparent;
    top: 74px;
    height: calc(100% - 74px);
  }
  &__wrap {
    width: 100%;
    padding: 6px 0 0 6px;
    max-width: 826px;
    position: relative;
    margin: auto;
    @media only screen and (max-width: 749px) {
        width: 95%;
        // margin-left: 0;
        padding: 0;
    }   
    &:after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 77%;
      height: 80%;
      background: $accent;
      z-index: -1;
      @media only screen and (max-width: 749px) {
        content: none;
      }
    }
  }
  &__body {
    position: relative;
    background: #eeeeee;
    padding: 6px 8px 75px;
    text-align: center;
    @media only screen and (max-width: 749px) {
      background: #2a2a2b;
      color: #fff;
    }
  }
  &__header {
    background: #262627;
    color: $accent;
    font-size: 36px;
    margin-bottom: 35px;
    padding: 43px 15px 20px;
    @media only screen and (max-width: 749px) {
      font-size: 18px;
    }
  }
  &__text {
    font-size: 18px;
    line-height: 1.5;
    margin: 0 auto 25px;
    max-width: 625px;
  }
  &__title {
    font-size: 30px;
    margin-bottom: 16px;
    color: #ff0600;
    @media only screen and (max-width: 749px) {

    }
  }
  &__phone {
    display: block;
    font-size: 30px;
    font-weight: 700;
    margin-bottom: 25px;
  }
  &__bottom {
    font-style: italic;
    font-size: 24px;
    line-height: 28px;
    padding-top: 20px;
    a {
      font-weight: 700;
      text-decoration: underline;
    }
  }
  &__group {
    display: flex;
    justify-content: space-between;
  }

  &-open {
      display: block;
  }

  &__pic {
    position: relative;
    img {
      width: 100%;
    }
    .modal__phone {
      position: absolute;
      bottom: 30px;
      right: 30px;
      font-size: 24px;
      font-weight: 400;
      margin-bottom: 0;
    }
  }
  &__img {
    position: relative;
    width: 50%;
    @media only screen and (max-width: 749px) {
      display: none;
    }
    &:after {
      content: "";
      position: absolute;
      top: -17px;
      left: -12px;
      width: 545px;
      height: 458px;
      background: url('../img/cons.png') no-repeat;
    }
  }
  &__form {
    width: 47%;
    padding: 5px 3%;
    @media only screen and (max-width: 749px) {
      width: 100%;
    }
  }
  &__about {
    margin-bottom: 18px;
    @media only screen and (max-width: 749px) {
      font-size: 16px;
      max-width: 380px;
      margin-left: auto;
      margin-right: auto;
    }
  }
}
.form {
  position: relative;
  max-width: 235px;
  margin: 0 auto;
  @media only screen and (max-width: 749px) {
    max-width: 380px;
  }
  &__btn {
    font-weight: normal;
    // background: transparent;
    // padding: 0;
  }
  &-line {
    &+.form-line {
      margin-top: 21px;
    }
    &-btn {
      margin-top: 29px;
    }
  }
  .btn {
    min-width: auto;
    box-shadow: none;
  }
}
.close {
  position: absolute;
  right: 6px;
  top: 7px;
  width: 46px;
  height: 46px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  font-size: 30px;
  color: #fff;
  transition: 0.3s;

  &:hover {
        color: rgba(255, 255, 255, 0.7);
  }
}
  
.animated {
    animation-duration: 1s;
    animation-fill-mode: both;
}
.disabled {
    display: none !important;
}

@keyframes fadeOutUp {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: translate3d(0, -100%, 0);
  }
}
  
.fadeOutUp {
  animation-name: fadeOutUp;
}
@keyframes fadeInDown {
  from {
    opacity: 0;
    transform: translate3d(0, -100%, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.fadeInDown {
  animation-name: fadeInDown;
}
#modalMap {
  .modal {
    &__body {
      padding-bottom: 6px;
    }
  }
}
#modalCons {
  .modal {
    &__wrap {
      margin-left: auto;
    }
    &__body {
      padding-bottom: 40px;
    }
    &__header {
      font-size: 30px;
      margin-bottom: 27px;
      @media only screen and (max-width: 749px) {
        font-size: 18px;
      }
    }
  }
}
#modalCallback{
  .modal {
    &__body {
      padding-bottom: 40px;
    }
    &__about {
      margin-bottom: 28px;
    }
    &__header {
      font-size: 30px;
      margin-bottom: 27px;
    }
    &__img:after {
      background: url('../img/callback-bg.png') no-repeat;
      width: 544px;
      height: 345px;

    }
    &__wrap {
      max-width: 830px;
    }
  }
}
#modalFinish {
  .modal{
    &__wrap {
      max-width: 420px;
      text-align: center;
      @media only screen and (max-width: 749px) {
        //     max-width: 100%;
          width: 95%;
          padding-right: 1%;
      }
    }
    &__body {
      
      padding: 65px 40px 64px;
      background: #262627;
      color: #fff;
      @media only screen and (max-width: 749px) {
        padding-left: 20px;
        padding-right: 20px;
        padding-bottom: 73px;
      }
    }
    &__title {
      color: #ffcc33;
      @media only screen and (max-width: 749px) {
        font-size: 45px;
        line-height: 45px;
        margin-bottom: 18px;
      }
    }
    &__subtitle {
      text-align: center;
      font-size: 18px;
      line-height: 28px;
      @media only screen and (max-width: 749px) {
        line-height: 25px;
      }
    }
  }
  .close {
    // color: #262627;
  }

}
#modalMap {
  @media only screen and (max-width: 749px) {
    background-color: rgba(0, 0, 0, 0.8);
  }
  .modal__pic .modal__phone {
    @media only screen and (max-width: 749px) {
      display: none;
      // bottom: 9px;
      // right: 13px;
      // font-size: 21px;
    }
    // @media only screen and (max-width: 419px) {
    //   bottom: 6px;
    //   right: 13px;
    //   font-size: 21px;
    // }
  }
}
#modalShop {
  .modal__body {
    @media only screen and (max-width: 749px) {
      background: #eeeeee;
      color: #262627;
    }
  }
}
.details {
    position: relative;
    z-index: 1;
    margin-bottom: 30px;
    @media only screen and (min-width: 1500px) {
        padding-top: 50px;
    }
    @media only screen and (max-width: 767px){
        overflow: hidden;
        margin-bottom: 0;
    }
    .title {
        margin-bottom: 7px;
    }
    &__container {
        position: relative;
        &:before {
            content: "";
            position: absolute;
            top: 2%;
            left: 0;
            background: url('../img/det-text-bg.png') no-repeat;
            width: 59%;
            height: 86%;
            background-size: 100% 100%;
            z-index: 1;
            @media only screen and (max-width: 991px){
                width: 83%;
            }
            @media only screen and (max-width: 767px){
                width: 109%;
                z-index: -1;
                top: -2%;
            }
        }
    }
    &__first {
        position: absolute;
        top: 0;
        right: 0;
        width: 100%;
        height: 100%;
        @media only screen and (max-width: 767px){
            top: 347px;
            height: 420px;
        }
        @media only screen and (max-width: 421px){
            top: 381px;
        }
        @media only screen and (max-width: 419px){
            height: 284px;
        }
        @media only screen and (max-width: 419px){
            // top: 447px;
            // height: 284px;
        }
        @media only screen and (max-width: 415px){
            top: 381px;
        }
        @media only screen and (max-width: 380px){
            top: 410px;
        }
        @media only screen and (max-width: 328px){
            top: 454px;
        }
        &:after {
            content: "";
            position: absolute;
            top: 0;
            right: 0;
            width: 49%;
            height: 100%;
            background: url('../img/zapch-bg.png') no-repeat;
            background-size: contain;
            background-position: top right;
            pointer-events: none;
            @media only screen and (min-width: 1780px) {
                background-size: cover;
            }
            @media only screen and (max-width: 991px){
                background-size: cover;
            }
            @media only screen and (max-width: 767px){
                width: 100%;
                background-position: bottom right;
            }
        }
        &:before {
            content: "";
            position: absolute;
            bottom: -9px;
            left: 0;
            width: 100%;
            height: 50%;
            background: #fff;
            transform: skew(0, 1.2deg);
            box-shadow: 0.105px 2.998px 7.5px rgba(0,0,0,0.51);
            z-index: -1;
        }
    }
    &__info {
        position: relative;
        padding: 127px 0 50px;
        z-index: 1;
        @media only screen and (max-width: 991px){
            padding-top: 77px;
        }
        @media only screen and (max-width: 767px){
            padding-top: 46px
            ;
        }
        p {
            margin-top: 0;
            margin-bottom: 21px;
        }
        @media only screen and (min-width: 992px) {
            // padding-top: 55px;
        }

        // &:after {
        //     content: "";
        //     position: absolute;
        //     top: 1%;
        //     right: -38%;
        //     width: 248%;
        //     height: 83%;
        //     background: #eeeeee;
        //     transform: skew(12deg, 2deg) perspective(3062px) rotateY(25deg);
        //     box-shadow: 0.105px 2.998px 3.5px rgba(0, 0, 0, 0.29);
        //     z-index: -1;
        // }
    }
    &__btn {
        display: flex;
        margin-top: 83px;
        margin-left: 27px;
        letter-spacing: -0.5px;
        @media only screen and (max-width: 991px){
            margin-top: 23px;
        }
        @media only screen and (max-width: 767px){
            margin-top: 410px;
            margin-left: 0;
            position: relative;
            letter-spacing: 0;
        }
        @media only screen and (max-width: 419px){
            margin-top: 249px;
        }
        @media only screen and (max-width: 380px){
            margin-top: 279px;
        }
    }
}
.repair {
    position: relative;
    overflow: hidden;
    padding: 100px 0 150px;
    margin-top: -65px;
    margin-bottom: -115px;
    background: #eeeeee;
    @media only screen and (max-width: 991px){
        margin-top: -135px;
    }
    @media only screen and (max-width: 767px){
        margin-top: -76px;
        padding-bottom: 50px;
    }
    .title {
        margin-bottom: 28px;
    }
    &__container {
        position: relative;
        &:before {
            content: "";
            position: absolute;
            top: 0;
            right: 0;
            background: url('../img/repair-text-bg.png') no-repeat;
            width: 60%;
            height: 100%;
            background-size: 100% 100%;
            z-index: 1;
            @media only screen and (max-width: 991px){
                width: 82%;
            }
            @media only screen and (max-width: 767px){
                width: 110%;
                top: -41px;

            }
        }
    }
    &__first {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        @media only screen and (min-width: 1780px) {
            width: 53%;
        }
        @media only screen and (max-width: 767px){
            width: 100%;
            height: 270px;
            top: 207px;
            z-index: 1;
        }
        
        @media only screen and (max-width: 500px){
            top: 207px;
            height: 220px;
        }
        @media only screen and (max-width: 465px){
            top: 232px;
        }
        @media only screen and (max-width: 409px){
            height: 220px;
            top: 260px;
        }
        @media only screen and (max-width: 351px){
            top: 305px;
            height: 191px;
        }
        &:after {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: url('../img/repairs-bg.png') no-repeat;
            background-size: contain;
            background-position: top left;
            pointer-events: none;
            @media only screen and (min-width: 1780px) {
                background-size: cover;
            }
            @media only screen and (max-width: 991px){
                width: 79%;
                background-position: top right;
                background-size: cover;
            }
            @media only screen and (max-width: 767px){
                width: 104%;
                background-position: center center;
                background-image: url('../img/repairs-bg-mob.png');
            }
        }
    }
    &__info {
        position: relative;
        padding-top: 69px;
        padding-right: 2%;
        z-index: 1;
        // &:after {
        //     content: "";
        //     position: absolute;
        //     top: 0%;
        //     left: -26%;
        //     width: 213%;
        //     height: 94%;
        //     background: #fff;
        //     transform: skew(-11deg, -1deg) perspective(380px) rotateY(-3deg);
        //     box-shadow: 0.105px 2.998px 3.5px rgba(0, 0, 0, 0.29);
        //     z-index: -1;
        // }
        @media only screen and (max-width: 767px){
            padding-top: 15px;
            padding-right: 0;
        }
        .tags {
            margin-top: 42px;
            margin-bottom: 20px;
            line-height: 1.5;
        }
    }
    &__btn {
        max-width: 320px;
        @media only screen and (max-width: 767px){
            max-width: 380px;
            margin-top: 380px;
            margin-left: auto;
            margin-right: auto;
        }
        @media only screen and (max-width: 500px){
            margin-top: 340px;
        }
        @media only screen and (max-width: 449px){
            margin-top: 320px;
        }
        @media only screen and (max-width: 409px){
            max-width: 320px;
            margin-top: 280px;
            max-width: 320px;
            margin-top: 300px;
        }
        @media only screen and (max-width: 351px){
            margin-top: 320px;
        }
        .btn {
            letter-spacing: 0;
            @media only screen and (max-width: 767px){
                top: -94px;
            }
            @media only screen and (max-width: 500px){
                top: -122px;
            }
            @media only screen and (max-width: 409px){
                top: -87px;
            }
            @media only screen and (max-width: 351px){
                top: -123px;
            }
        }
    }
}
.about {
    padding: 43px 0 40px;
    // box-shadow: 0.105px 2.998px 0 3.5px rgba(0,0,0,0.29);
    box-shadow: 0px 0px 17px 1px rgba(0,0,0,0.29);
    margin-top: -14px;
    position: relative;
    z-index: 1;
    @media only screen and (max-width: 767px){
        overflow: hidden;
        margin-top: 0;
        padding-top: 23px;
    }
    &__container {
        position: relative;

        &:before {
            content: "";
            position: absolute;
            top: -3%;
            left: 0;
            background: url('../img/about-text-bg.png') no-repeat;
            width: 63%;
            height: 120%;
            background-size: 100% 100%;
            z-index: -1;
            @media only screen and (max-width: 991px){
                width: 82%;
            }
            @media only screen and (max-width: 767px){
                background-image: url('../img/about-text-bg-mob.png');
                width: 100%;
                height: 108%;
            }
        }
    }
    .title {
        margin-bottom: 29px;
    }
    &__first {
        position: absolute;
        top: 0;
        right: 0;
        width: 100%;
        height: 100%;
        @media only screen and (max-width: 767px){
            z-index: 1;
            top: 121px;
            height: 398px;
        }
        @media only screen and (max-width: 567px){
            top: 150px;
        }
        @media only screen and (max-width: 382px){
            top: 207px;
        }
        &:after {
            content: "";
            position: absolute;
            top: 0;
            right: 0;
            width: 63%;
            height: 119%;
            background: url('../img/about-bg.png') no-repeat;
            background-size: contain;
            background-position: top right;
            pointer-events: none;
            z-index: -2;
            @media only screen and (min-width: 1780px) {
                background-size: cover;
            }
            @media only screen and (max-width: 767px){
                width: 141%;
                height: 265px;
                right: -19%;
                background-size: 100%;
            }
            @media only screen and (max-width: 382px){
                height: 227px;
            }
        }
    }
    
    &__wrap {
        position: relative;
        // &:after {
        //     content: "";
        //     position: absolute;
        //     top: 1%;
        //     right: -4%;
        //     width: 148%;
        //     height: 100%;
        //     background: #eeeeee;
        //     transform: skew(26deg, 4deg) perspective(292px) rotateY(-4deg);
        //     z-index: -1;
        //     box-shadow: 0.105px 2.998px 3.5px rgba(0,0,0,0.29);
        //     // filter: drop-shadow(0.105px 2.998px 7.5px rgba(0,0,0,0.51))
        // }
        // &:before {
        //     content: "";
        //     position: absolute;
        //     top: -2%;
        //     right: -3%;
        //     width: 148%;
        //     height: 100%;
        //     background: $accent;
        //     transform: skew(27deg, 5deg) perspective(243px) rotateY(-4deg);
        //     z-index: -1;
        // }

    }
    .container {
        position: relative;
        z-index: 2;
    }
    &__item {
        padding: 0 10%;
        @media only screen and (max-width: 767px){
            padding: 0;
            margin-bottom: 38px;
        }
        &:hover {
            .icon {
                fill: #fff;
            }
            .about__pic {
                box-shadow: 0.105px 2.998px 7.5px rgba(0,0,0,0.51);
            }
        }
    }
    &__info {
        padding: 115px 36px 110px 0;
        @media only screen and (max-width: 1279px){
            padding: 55px 36px 40px 0;
        }
        @media only screen and (max-width: 767px){
            padding: 27px 0 20px;
        }
        p {
            margin: 0 0 18px 0;
            &:first-child {
                @media only screen and (max-width: 767px){
                    margin-bottom: 300px;
                }
                @media only screen and (max-width: 567px){
                    margin-bottom: 330px;
                }
                @media only screen and (max-width: 419px){
                    margin-bottom: 290px;
                }
            }
        }
    }
    &__group {
        margin-top: 126px;
        @media only screen and (max-width: 767px){
            margin-top: 76px;
        }
    }
    &__title {
        font-size: 24px;
        text-align: center;
        font-weight: 700;
        margin-bottom: 10px;
        @media only screen and (max-width: 767px){
            font-size: 23px;
            margin-bottom: 23px;
        }
    }
    &__subtitle {
        max-width: 1078px;
        margin: 0 auto 43px;
        font-size: 18px;
        text-align: center;
        @media only screen and (max-width: 991px){
            font-size: 17px;
        }
        @media only screen and (max-width: 767px){
            font-size: 18px;
            margin-bottom: 53px;
        }
    }
    &__pic {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 154px;
        height: 154px;
        margin: 0 auto 20px;
        border-radius: 50%;
        background: $accent;
        box-shadow: 0 0 0 rgba(0,0,0,0);
        @media only screen and (max-width: 991px){
            width: 100px;
            height: 100px;
        }
        @media only screen and (max-width: 767px){
            width: 154px;
            height: 154px;
        }
    }
    .icon {
        max-width: 85px;
        max-height: 88px;
        fill: #262628;
        transition: 0.3s;
        @media only screen and (max-width: 991px){
            max-width: 63px;
            max-height: 57px;
        }
        @media only screen and (max-width: 767px){
            max-width: 85px;
            max-height: 88px;
        }
    }
    &__name {
        text-transform: uppercase;
        font-size: 18px;
        font-weight: 700;
        text-align: center;
        margin-bottom: 10px;
    }
    &__text {
        text-align: center;

    }
}